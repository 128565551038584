import React from "react"

import Layout from "@src/components/Layout"
import SplashWithIllustration from "@src/components/SplashWithIllustration"
import CommunityCallout from "@src/components/CommunityCallout"
import Callout from "@src/components/Callout"
import getAppUrl from "@src/utils/getAppUrl"
import Section from "@src/components/Section"
import Text from "@src/components/Text"
import track from "@src/utils/track"

import IntegrationsIcon from "@src/icons/v2/rest-graphql.svg"
import SecureIcon from "@src/icons/v2/data-secure.svg"
import IntegrationGrid from "@src/icons/v2/integrations-grid.svg"
import ImportingDatabase from "@src/icons/screenshots/importing-database.png"
import LinkToDatabase from "@src/icons/screenshots/link-to-database.png"
import OG from "@src/icons/og/OG_Datasources.jpeg"

import * as styles from "./styles.module.scss"
import Splash from "./components/Splash"
import Horizontal from "./components/Horizontal"
import Row from "../components/Row"
import ImageColumn from "./components/ImageColumn"
import ImageRow from "./components/ImageRow"

const IndexPage = ({ location }) => {
  const appUrl = getAppUrl.hook(location)
  return (
    <Layout
      title="Build on powerful APIs on top of your existing data."
      description="Add workflows, APIs, triggers and business logic to existing data sources."
      url="https://canonic.dev/features/data-sources"
      location={location}
      ogImage={OG}
    >
      <Splash
        title="Build on top of existing datasources."
        subtitle="Already have a database created? Canonic allows you to import or link databases and generate APIs for them."
        icon={<IntegrationGrid />}
      />
      <Row>
        <Horizontal>
          <ImageColumn
            title="Import database"
            subtitle="Import a MS SQL, Airtable, MongoDB, PostGres, MySQL or Google Sheets and Canonic will autogenerate the schema model of that database."
            screenshot={ImportingDatabase}
          />
          <ImageColumn
            title="Link Database"
            subtitle="Connect to an existing database and start building APIs on top of that. Canonic will use the linked database as storage."
            screenshot={LinkToDatabase}
          />
        </Horizontal>
      </Row>
      <Row>
        <ImageRow
          title="Instantly create APIs from existing data sources"
          subtitle="Build APIs to query, filter or update your data from an existing datasource in a matter of minutes."
          icon={IntegrationsIcon}
        />
      </Row>
      <Row>
        <ImageRow
          title="Data remains secure with you "
          subtitle="The data never leaves your servers and our secure API builder provides you with complete control."
          icon={SecureIcon}
          reverse
        />
      </Row>
      <Callout location={location} title="dataSources" />
      <CommunityCallout />
    </Layout>
  )
}

export default IndexPage
